function run() {
    // Light up the title
    new FlashLight(document.querySelector('h1'))
}

// Hello. Just browsing?
// This a little flashlight function to light up a bit of text in a neat way.
class FlashLight {
    constructor(element) {
        // "Height" of the light source in px
        this.sourceHeight = 1000
        // Depth of the shadow projection in px
        this.projectedHeight = 30
        this.targets = []
        this.shadows = document.createElement('div')
        this.shadows.classList.add('shadows')
        this.transformToTargets(element)
        document.querySelector('body').append(this.shadows)
        this.bindEvents()
        this.illuminate()
    }
    bindEvents() {
        document.addEventListener('mousemove', this.illuminate.bind(this))
        window.addEventListener('resize', this.illuminate.bind(this))
    }
    transformToTargets(element) {
        const text = element.textContent
        element.innerHTML = ''
        for (let i=0; i < text.length; i++) {
            const target = document.createElement('span')
            target.textContent = text[i]
            const shadow = target.cloneNode(true)
            this.shadows.appendChild(shadow)
            target.shadow = shadow
            this.targets.push(target)
            element.appendChild(target)
        }
    }
    illuminate({ pageX, pageY }={}) {
        pageX = pageX || window.innerWidth / 2
        pageY = pageY || window.innerHeight / 2
        for(const target of this.targets) {
            const dims = target.getBoundingClientRect()
            const targetCoords = [dims.x + dims.width / 2, dims.y + dims.height / 2]
            const delta = [pageX - targetCoords[0], pageY - targetCoords[1]]
            const distance2D = Math.sqrt(Math.pow(delta[0], 2) + Math.pow(delta[1], 2))
            // Angle of 2D shadow projection
            let angle2D = Math.atan(-delta[1] / delta[0])
            // Reciprocate angle if left of target
            if (delta[0] < 0) angle2D += Math.PI
            const distance3D = Math.sqrt(Math.pow(this.sourceHeight, 2) + Math.pow(distance2D, 2))
            // Angle of 3D shadow projection
            const angle3D = Math.atan(this.sourceHeight / distance3D)
            // Distance of shadow projection
            const projectedDistance3D = this.projectedHeight / Math.tan(angle3D)
            // 3D distance projected in 2D space
            const projectedDistance = Math.sqrt(Math.pow(projectedDistance3D, 2) - Math.pow(this.projectedHeight, 2))
            const projectedCoords = [
                targetCoords[0] - (Math.cos(angle2D) * projectedDistance),
                targetCoords[1] + (Math.sin(angle2D) * projectedDistance)
            ]
            // Approximate brightness of target/shadow based on distance and height of light source
            const brightness = Math.round(Math.pow(255, 1 - ((distance3D - this.sourceHeight) / 400)) * .95)
            const shadowBrightness = Math.round(Math.pow(255, ((projectedDistance3D - this.projectedHeight) / 40)))
            const definition = Math.min(30, Math.max(1, projectedDistance / 4))
            target.shadow.style.left = `${projectedCoords[0] - dims.width / 2}px`
            target.shadow.style.top = `${projectedCoords[1] - dims.height / 2}px`
            target.shadow.style.textShadow = `0 0 ${definition}px rgba(${shadowBrightness},${shadowBrightness},${shadowBrightness},0.2)`
            target.style.color = `rgb(${brightness},${brightness},${brightness})`
        }
    }
}

document.addEventListener('DOMContentLoaded', run)